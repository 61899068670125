@use 'variables';

.wrapper {
  .sidebar {
    height: 100%;

    aside {
      .profile {

        margin-bottom: 30px;

        text-align: center;

        img {
          display: block;
          width: 100px;
          height: 100px;
          border-radius: 50%;
          margin: 0 auto;
        }

        h3 {
          color: variables.$white;
          margin: 10px 0 5px;
        }
      }

      p {
        color: rgb(206, 240, 253);
        font-size: 14px;
      }

      // NAVBAR
      nav {
        ul {
          list-style: none;

          div.arrow {
            cursor: pointer;
            transition: all 0.5s ease;
            position: relative;
            width: 40px;
            height: 40px;

            margin-top: 8px;
            margin-bottom: 8px;
            /* Primary/700 */

            background: #002955;
            border-radius: 8px 0px 0px 8px;

            svg {
              position: absolute;
              width: 16px;
              height: 14.03px;
              left: 12px;
              top: 13px;
            }
          }

          li {
            img {
              transition: all 0.5s ease;
              position: absolute;
              width: 170px;
              height: 241px;
              left: 39px;
              //top: 452px;
            }

            a {
              text-decoration: none;

              display: block;
              padding: 13px;

              color: rgb(237, 237, 241);

              /* or 19px */
              display: flex;
              align-items: center;

              position: relative;

              span.icon {
                // position: absolute;
                color: #dee4ec;

                left: 6.45%;
                right: 81.45%;
                top: 23.21%;
                bottom: 23.21%;
              }

              span.item {
                //font-family: "Roboto";
                //font-style: normal;
                font-weight: 700;
                font-size: 16px;
                //line-height: 120%;
                position: relative;
                left: 25px;
                /*    left: 20.61%;
                right: 43.55%;
                top: 32.14%;
                bottom: 33.93%; */
              }

              &:hover {
                background: #002955;
              }

              &.active {
                position: relative;
                //color: #0c7db1;
                background: variables.$primary;
                border-left: 4px solid variables.$white;
              }

              &:hover .icon,
              &.active .icon {
                color: variables.$white;
              }
            }

            ul {
              li {
                a {
                  text-decoration: none;

                  display: block;
                  padding: 13px;

                  color: rgb(237, 237, 241);

                  /* or 19px */
                  display: flex;
                  align-items: center;

                  position: relative;

                  span.item {
                    font-weight: 600;
                    font-size: 14px;
                    position: relative;
                    left: 25px;
                  }

                  &:hover {
                    background: #002955;
                  }

                  &.active {
                    position: relative;
                    background: variables.$primary;
                    border-left: 4px solid variables.$white;
                  }

                  &:hover .icon,
                  &.active .icon {
                    color: variables.$white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// SIDEBAR ACTIVE
.wrapper.active {
  aside {
    nav {
      ul {
        li {
          a {
            transition: all 0.5s ease;
            width: 45vh;
          }

          img {
            width: 36px;
            height: 51px;
            left: 13px;
          }
        }
      }
    }
  }

  // HOME WIDTH
  .section {
    div.content {
      .button.back {
        transition: all 0.5s ease;
        // margin-left: 60em;
      }

      /*    a.item {
       width: 416px;
      } */
    }
  }
}

@media screen and (max-width: 1600px) {
  .wrapper.active {

    // HOME WIDTH
    .section {
      div.content {
        .button.back {
          transition: all 0.5s ease;

        }

        a.item {
          width: 325px;
        }

        a.item-rastrea {
          width: 150px;
        }
      }
    }
  }
}

@media (min-width: 1745px) {
  .wrapper.active {

    // rastreabilidade WIDTH
    .section {
      div.content {
        .button.back {
          transition: all 0.5s ease;
          margin-left: 21em;
        }

        a.item-rastrea {
          width: 210px;
        }
      }
    }
  }
}

@media (max-height: 720px) {
  .wrapper {
    min-height: 950px;
    .sidebar {
      //height: 300%;
      min-height: 1200px;
      height: 100%;
    }

  }

}
