$navbar-height: 60px;
$sidebar-width:260px;
$sidebar-pinned-wdth:70px;
$white: #fff;
$dark: #212121;
$dark-100: #9F9F9F;
$light: #FFFFFF;
$light-100: #EAEAEA;
$background: #f5f0f3;
$primary: #004877;
$primary-light: #de4eaf;
$primary-dark: #ab3585;
$secondary: #99CA3C;
$success: #00D68F;
$info: #00D68F;
$warning: #FFAA00;
$danger: #F43841;
$primary-900: #001539;


