%button{
    position: relative;
    cursor: pointer;
    color: #ffffff;
    border: 0px;
    font-size: 20px;
    border-radius: 10px;
    background: linear-gradient(49.93deg, #001d45 1.27%, #2b81ad 99.91%);
    border-radius: 10px;
    &:hover {
      background: rgba(43, 129, 173, 1);
    }
    &:disabled {
      background: #cccccc;
      color: #585858;
      cursor: no-drop;
    }
    &.cancel {
        background: #cccccc;
      }
}