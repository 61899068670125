%dropdown {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 3.8em;
  margin-top: 30px;
  border-radius: 10px;
  background: white;
  height: 36px;
  width: 258px;
  border: 1px solid #585858;
  svg {
    display: none;
  }
  & > span {
    transition: all 100ms ease-out;
    font-size: 14px;
    position: relative;
    line-height: 17px;
    left: 10px;
    color: rgba(88, 88, 88, 1);

    text-align: right;
  }
  user-select: none;
  &.is-active {
    border: double 1px transparent;
    border-radius: 10px;
    background-image: linear-gradient(white, white),
      radial-gradient(
        circle at top left,
        rgba(0, 29, 69, 1),
        rgba(43, 129, 173, 1)
      );
    background-origin: border-box;
    background-clip: padding-box, border-box;
    svg {
      display: block;
      position: relative;
      margin-left: 10px;
    }
    span {
      position: absolute;
      left: 40px;
      color: #002955;
    }
  }
  &:hover {
    span {
      color: #002955;
    }
    border: double 1px transparent;
    border-radius: 10px;
    background: linear-gradient(white, white) padding-box,
      radial-gradient(
          circle at top left,
          rgba(0, 29, 69, 1),
          rgba(43, 129, 173, 1)
        )
        border-box;
    &:after {
      border: solid transparent;
      border-width: 0 2px 2px 0;
      border-image: linear-gradient(49.93deg, #001d45 1.27%, #2b81ad 99.91%) 1;
    }
  }
  &:after {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    border: solid #585858;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    top: 42%;
    right: 23px;
    margin-top: -3px;
  }
}

%list-dropdown {
  background: white;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 85%; /* align the dropdown right below the dropdown text */
  border: 1px solid rgba(204, 204, 204, 1);
  border-radius: 0 0 10px 10px;
  border-top: none;
  left: -1px; /* align the dropdown to the left */
  right: -1px; /* align the dropdown to the right */
  opacity: 0; /* hide the dropdown */

  max-height: 288px;
  overflow: overlay;
  overflow-x: hidden;
  pointer-events: none; /* avoid mouse click events inside the
  dropdown */
  z-index: -1;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    opacity: 0;
  }

  &::-webkit-scrollbar-thumb {
    background: #d8d8d8;
    height: 92px;

    border-radius: 5px;
  }

  li label {
    display: block;
    border-bottom: 1px solid silver;
    padding: 10px;
    transition: all 0.2s ease-out;
  }
}

%dropdown-active {
  border: 11px solid #585858;
  %list-dropdown {
    opacity: 1; /* display the dropdown */
    pointer-events: auto; /* make sure that the user still can select checkboxes */
  }
}



