/* You can add global styles to this file, and also import other style files */
@use "assets/lightning-admin.scss";

@import '@angular/material/theming';
@include mat-core();

$md-mcgpalette0: (
    50 : #e0e9ef,
    100 : #b3c8d6,
    200 : #80a4bb,
    300 : #4d7fa0,
    400 : #26638b,
    500 : #004877,
    600 : #00416f,
    700 : #003864,
    800 : #00305a,
    900 : #002147,
    A100 : #7babff,
    A200 : #004877,
    A400 : #156aff,
    A700 : #005bfb,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$candy-app-primary: mat-palette($md-mcgpalette0);
$candy-app-accent:  mat-palette($md-mcgpalette0, A200, A100, A400);


$candy-app-warn:    mat-palette($md-mcgpalette0);

$candy-app-theme: mat-light-theme((
  color: (
    primary: $candy-app-primary,
    accent: $candy-app-accent,
    warn: $candy-app-warn,
  )
));
@include angular-material-theme($candy-app-theme);

* {
  margin: 0;
  padding: 0;
  /*   box-sizing: border-box; */
  outline: 0;
  font-family: "Roboto";
  //font-style: normal;
  font-weight: 700;
}

@media (max-width: 1080px) {
  html {
    font-size: 58%;
  }
}

@media (max-height: 720px) {
  html {
    font-size: 12 px;
    zoom: .75;
  }

  .cdk-overlay-connected-position-bounding-box {
    width: auto !important;
    height: auto !important;
    bottom: 200px;
  }
}

.row {
  display: flex;
}

.button {
  @extend %button;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  content: "";
  position: absolute;
  width: 7px;
  height: 7px;
  border: solid #585858;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  top: 42%;
  right: 23px;
  margin-top: -3px;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  content: "";
  border: solid transparent;
  border-width: 0 2px 2px 0;
  border-image: linear-gradient(49.93deg, #001d45 1.27%, #2b81ad 99.91%) 1;
}

.select-items {
  @extend %list-dropdown;
  opacity: 1;
  top: 40px;
  width: 258px;
  background: white;
  position: absolute;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 0 0 10px 10px;
  border-top: none;
  left: 32px;
  right: -1px;
  max-height: 288px;
  overflow: overlay;
  overflow-x: hidden;
  pointer-events: all;
  z-index: 0;

  div {
    border-bottom: 1px solid silver;
    padding: 10px;
    display: block;
    font-size: 14px;
    font-weight: 400;
  }
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
  z-index: -1;
}


.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.select-selected {
  line-height: 40px;
  font-size: 14px;
  z-index: 1;
}

.same-as-selected {
  color: #002955;
}

.form-row {
  width: 584px;
  display: flex;
  flex-wrap: wrap;

  &-md {
    width: auto;
    display: flex;
    flex-wrap: wrap;
  }
}

.form-group {
  select {
    display: none;
    /*hide original SELECT element:*/
  }

  position: relative;
  margin-top: 20px;

  &:focus .form-input,
  &:hover .form-input {
    border: double 1px transparent;
    border-radius: 10px;
    background-image: linear-gradient(white, white),
      radial-gradient(circle at top left,
        rgba(0, 29, 69, 1),
        rgba(43, 129, 173, 1));
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }

  &:hover .form-label,
  &:focus .form-label {
    color: #004877;
  }

  &.focus {
    z-index: 2;
  }
}

.form-label {
  z-index: 1;
  transition: all 0.5s ease;
  display: block;
  position: relative;
  left: 44px;
  right: 0%;
  top: -16px;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: #585858;
  width: max-content;
  background-color: #fff;

  ::placeholder {
    position: absolute;
    left: 6.07%;
    right: 0.91%;
    top: 30.1%;
    bottom: 27.59%;
    font-size: 14px;
    line-height: 120%;
    /* identical to box height, or 17px */
    color: #004877;
  }

  &.focus {
    transform: translateY(-130%);
    -moz-transform: translateY(-130%);
    -webkit-transform: translateY(-130%);
    -ms-transform: translateY(-130%);
    // font-size: 1.25em;
  }
}

.form-input {
  box-sizing: border-box;
  position: relative;
  margin-right: 2.222vw;
  padding: 0 15px;
  border: 1px solid #585858;
  height: 36px;
  width: 17.917vw;
  left: 32px;
  top: 9px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.2);
  color: #004877;

  &:hover+.form-label {
    color: #004877;
  }

  &:focus+.form-label {
    transform: translateY(-130%);
    -moz-transform: translateY(-130%);
    -webkit-transform: translateY(-130%);
    -ms-transform: translateY(-130%);
    //font-size: 1.25em;
    color: #004877;
  }

  &.select {
    option {
      font-size: 16px;
    }
  }

  &-select,&.select {
    @extend .form-input;

    &-focus {
      border: double 1px transparent;
      border-radius: 10px;
      background-image: linear-gradient(white, white),
        radial-gradient(circle at top left,
          rgba(0, 29, 69, 1),
          rgba(43, 129, 173, 1));
      background-origin: border-box;
      background-clip: padding-box, border-box;
    }
  }
}

.alert {
  position: absolute;
  color: red;
  left: 35px;
  z-index: -1;
  font-size: 12px;
}

.mat-dialog-container {
  padding: 0 !important;
  border-radius: 15px !important;
}

.radio-input {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 450px;

  span {
    color: #212121;
    font-size: 14px;
    font-weight: 600;

    strong {
      color: #CCCCCC;
      font-weight: 600;
    }
  }

  .mat-radio-button:not(.mat-radio-checked) {
    .mat-radio-label-content {
      color: #CCCCCC;
    }
  }

  .mat-radio-label-content {
    color: #003766;
  }

  .mat-radio-button {
    margin-left: 16px;
    font-size: 14px;
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.alert_error{
  background-color: #721c24;
  color: white;
  font-weight: bold;
}

.alert_success{
  background-color: #0f5132;
}


@media print {
  .top_navbar {
    display: none;
  }

  .top_hr {
    display: none;
  }

  .sidebar {
    display: none;
  }

  .section {
    margin-left: 0;
  }
}



.question-container {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .question-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #202124;
  }

  input[type="text"],
  textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #dadce0;
    border-radius: 4px;
    font-size: 16px;
    color: #202124;
    box-sizing: border-box;
    margin-bottom: 20px;
    transition: border-color 0.2s;

    &:focus {
      border-color: #1a73e8;
      outline: none;
    }
  }

  button {
    position: relative;
    cursor: pointer;
    border: 0px;
    background: linear-gradient(49.93deg, #001d45 1.27%, #2b81ad 99.91%);
    display: inline-block;
    color: white;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.2s;
    &:hover {
      background: rgba(43, 129, 173, 1);
    }
  }

  textarea {
    resize: vertical;
    min-height: 80px;
  }

  .option-container {
    margin-bottom: 10px;
    margin-left: 20px;

    input[type="checkbox"],
    input[type="radio"] {
      margin-right: 10px;
      transform: scale(1.2);
      cursor: pointer;
    }

    label {
      font-size: 16px;
      color: #202124;
      cursor: pointer;
      font-weight: normal;
    }
  }

  .conditional-container {
    margin-left: 20px;
    padding-left: 10px;
    border-left: 2px solid #dadce0;
    margin-top: 10px;
  }
}
